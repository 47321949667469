import { useEffect, useState, lazy, Suspense } from "react";

import config from "./config";

const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));
const Main = lazy(() => import("./components/Main"));

const renderLoader = () => <></>;

export default function App() {
  const [current, setCurrent] = useState(-1);
  const [form, setForm] = useState({
    length: config.questions.length,
  });

  const getForm = () => {
    return form;
  };

  useEffect(() => {
    const url = new URL(window.location);
    const q = url.searchParams.get("q");

    if (q) {
      setCurrent(config.questions.length);
    }
  }, []);

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Header />
      </Suspense>

      <Suspense fallback={renderLoader()}>
        <Main
          current={current}
          setCurrent={setCurrent}
          config={config}
          form={form}
          setForm={setForm}
          getForm={getForm}
        />
      </Suspense>

      {current >= 0 && current < config.questions.length && (
        <Suspense fallback={renderLoader()}>
          <Footer current={current} total={config.questions.length} />
        </Suspense>
      )}
    </>
  );
}
